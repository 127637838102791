<template>
  <div class="flex w-full">
    <slot name="bot-avatar"></slot>
    <div class="content" :class="{ 'my-message': isMyMessage }">
      <div class="flex flex-col" v-html="message.message"> </div>
      <div class="flex flex-col">
        <FlotoValueInput
          v-model="formData.value"
          :input-type="inputType"
          only-leaf-node-selectable
          :module-name="message.model || $constants.REQUEST"
          :options="messageOptions"
          :advanced-searchable="false"
          v-bind="{ ...additionalProps }"
          :get-popup-container="popupContainer"
          @selected="itemSelected"
        />
      </div>
    </div>
    <slot name="sender-avatar"></slot>
  </div>
</template>

<script>
import { refChoiceTypeMap } from '@components/flowbuilder/helpers/option-map'
import MessageMixin from '../../mixins/message-mixin'
export default {
  name: 'UserRefChoiceMessage',
  mixins: [MessageMixin],
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    inputType() {
      return (this.message || {}).inputType
        ? refChoiceTypeMap[(this.message || {}).inputType]
        : 'dropdown'
    },
    messageOptions() {
      return this.message.options ? this.message.options[0].response : []
    },
    additionalProps() {
      if (
        this.inputType === refChoiceTypeMap.technician &&
        this.message.groupId
      ) {
        return { groupId: this.message.groupId }
      }
      return {}
    },
  },
  methods: {
    itemSelected(valueOption) {
      if (!valueOption) {
        return
      }
      const text = valueOption.text || valueOption.name || valueOption.label
      const message = text
      this.$emit('send-message', {
        message,
        chatBrainValue: valueOption.id || valueOption.value || valueOption.key,
        chatBrainId: this.message.chatBrainId,
      })
    },
    popupContainer() {
      const element = this.$el.closest('.scroll-wrapper')
      if (element) {
        return element
      }
      return document.body
    },
  },
}
</script>
