<template>
  <div class="input-container flex items-center">
    <MInput
      v-model="value"
      class="message-input"
      auto-focus
      :rows="3"
      :disabled="disabled"
      :placeholder="$t('start_typing')"
      @keyup="onKeyUp"
    />
    <MIcon
      name="send-msg"
      size="lg"
      class="mx-2 send-icon"
      tabindex="-1"
      :disabled="isDisabled"
      @click="sendMessage"
    />
    <!-- <MButton
      tabindex="-1"
      shape="circle"
      :shadow="false"
      :disabled="isDisabled"
      @click="sendMessage"
    >
      <MIcon name="paper-plane" />
    </MButton> -->
  </div>
</template>

<script>
export default {
  name: 'Compose',
  props: {
    idleTimeout: { type: Number, default: 3000 },
    disabled: { type: Boolean, default: false },
    hasFile: { type: Boolean, default: false },
  },
  data() {
    this.timer = null
    this.startTypingCalled = false
    return {
      value: '',
    }
  },
  computed: {
    isDisabled() {
      if (this.hasFile) {
        return false
      }
      return this.disabled || !(this.value || '').trim()
    },
  },
  methods: {
    sendMessage() {
      if (this.value.trim()) {
        this.$emit('send-message', this.value)
        this.$nextTick(() => {
          this.value = ''
        })
      }
    },

    onKeyUp(e) {
      if (this.timer) {
        clearInterval(this.timer)
        if (!this.startTypingCalled) {
          this.$emit('start-typing')
          this.startTypingCalled = true
        }
      }
      this.timer = setTimeout(() => {
        this.startTypingCalled = false
        this.$emit('stop-typing')
      }, this.idleTimeout)
      if (e.key === 'Enter') {
        this.sendMessage()
      }
    },
  },
}
</script>
