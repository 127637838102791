<template>
  <MTimelineItem>
    <div>
      <template v-for="(part, index) in auditParts">
        <FlotoLink
          v-if="part.link"
          :key="part.text"
          target="_blank"
          :to="part.link"
        >
          {{ part.text }}
        </FlotoLink>
        <FlotoUserDrawer v-else-if="part.user" :key="index" :user="part.user" />
        <GeolocationDrawer
          v-else-if="part.geolocationAudit"
          :key="index"
          :tooltip-data="part.geolocationAudit.name"
          :all-locations="
            getLocationCoordinates(part.geolocationAudit.extraData)
          "
          :style="{ display: 'inline-flex' }"
        >
          <template v-slot:trigger>
            {{ part.geolocationAudit.name }}
          </template>
        </GeolocationDrawer>
        <ApprovalAssetUpdateDrawer
          v-else-if="part.assetAudit"
          :key="part.assetAudit.id"
          :stage-id="part.assetAudit.id"
        >
          <template v-slot:trigger>
            {{ $tc('asset_update') }}
          </template>
        </ApprovalAssetUpdateDrawer>

        <FlotoTaskDrawer
          v-else-if="part.task"
          :key="index"
          :task-id="part.task.id"
          disabled
        >
          <template v-slot:trigger="{ open }">
            <a @click="open">
              {{ part.text }}
            </a>
          </template>
        </FlotoTaskDrawer>
        <a
          v-else-if="part.externalLink"
          :key="part.text"
          :href="part.externalLink"
          target="_blank"
        >
          {{ part.text }}
        </a>
        <template v-else-if="part.tooltip">
          <MTooltip :key="part.text">
            <template v-slot:trigger>
              <span> '{{ part.text }}' </span>
            </template>
            {{ part.tooltip }}
          </MTooltip>
        </template>
        <template v-else>
          {{ part.text }}
        </template>
      </template>
    </div>
    <div class="text-neutral-light">
      <small>{{ createdAt | datetime }} ({{ createdAt | timeago }})</small>
      <slot name="action"></slot>
    </div>
    <template v-slot:dot>
      <MIcon :name="icon" class="text-neutral-light" />
    </template>
  </MTimelineItem>
</template>

<script>
import GeolocationDrawer from '@src/components/geolocation-drawer.vue'
import Replace from 'lodash/replace'
import ApprovalAssetUpdateDrawer from '@src/components/approval/components/approval-asset-update-drawer.vue'
import { authComputed } from '@state/modules/auth'

export default {
  name: 'Audit',
  components: { GeolocationDrawer, ApprovalAssetUpdateDrawer },
  props: {
    owner: { type: Number, required: true },
    text: { type: String, required: true },
    moduleName: { type: String, required: true },
    parentId: { type: Number, required: true },
    links: {
      type: Array,
      default() {
        return []
      },
    },
    createdAt: { type: Number, required: true },
    icon: { type: String, default: 'circle' },
  },
  data() {
    return {
      showDrawer: false,
    }
  },
  computed: {
    ...authComputed,
    auditParts() {
      if ((this.links || []).length) {
        const markedString = this.links.reduce((result, link) => {
          return Replace(` ${result}`, ` ${link.text}`, ` ##${link.text}## `)
        }, this.text)
        const auditParts = markedString
          .split('##')
          .filter(Boolean)
          .map((part) => {
            let obj = { text: part }
            const linkPart = this.links.find(({ text }) => text === part)
            if (linkPart) {
              obj = {
                ...obj,
                ...this.getLinkForModel(
                  linkPart.model,
                  linkPart.id,
                  linkPart.text,
                  linkPart.externalLink,
                  linkPart.extraData
                ),
              }
            }
            return obj
          })
        return auditParts
      }
      return [{ text: this.text }]
    },
  },
  methods: {
    getLinkForModel(model, id, text, externalLink, extraData) {
      if (this.isPortalLogin) {
        return {}
      }
      if (
        [
          this.$constants.REQUEST,
          this.$constants.PROBLEM,
          this.$constants.CHANGE,
          this.$constants.RELEASE,
        ].indexOf(model) >= 0
      ) {
        return {
          link: this.$modules.getModuleRoute('ticket', 'view', {
            params: { id, ticketType: model },
          }),
        }
      }
      if (
        [
          this.$constants.ASSET_HARDWARE,
          this.$constants.ASSET_SOFTWARE,
          this.$constants.ASSET_NON_IT,
          this.$constants.ASSET_CONSUMABLE,
        ].indexOf(model) >= 0
      ) {
        return {
          link: this.$modules.getModuleRoute('asset', 'view', {
            params: { id, assetType: model },
          }),
        }
      }
      if (model === this.$constants.SOFTWARE_LICENSE) {
        return {
          link: this.$modules.getModuleRoute('software-license', 'view', {
            params: { id },
          }),
        }
      }
      if (model === this.$constants.ASSET_MOVEMENT) {
        return {
          link: this.$modules.getModuleRoute(
            'asset-management',
            'movement-view',
            {
              params: { id },
            }
          ),
        }
      }
      if (
        (model === this.$constants.USER_SURVEY ||
          model === this.$constants.USER_SURVEY_SCHEDULE) &&
        externalLink
      ) {
        return {
          tooltip: externalLink,
        }
      }
      if (model === this.$constants.USER_SURVEY) {
        return {
          link: this.$modules.getModuleRoute('user-survey', 'view', {
            params: { id },
          }),
        }
      }
      if (model === this.$constants.USER_SURVEY_SCHEDULE) {
        return {
          link: this.$modules.getModuleRoute(
            'user-survey',
            'edit-schedule-survey',
            {
              params: { id },
            }
          ),
        }
      }
      if (model === this.$constants.CONTRACT) {
        return {
          link: this.$modules.getModuleRoute('contract', 'view', {
            params: { id },
          }),
        }
      }
      if (model === this.$constants.PURCHASE) {
        return {
          link: this.$modules.getModuleRoute('purchase', 'view', {
            params: { id },
          }),
        }
      }
      if (model === this.$constants.PATCH) {
        return {
          link: this.$modules.getModuleRoute('patch', 'view', {
            params: { id },
          }),
        }
      }
      if (model === this.$constants.PATCH_REMOTE_DEPLOYMENT) {
        return {
          link: this.$modules.getModuleRoute('remote-deployment', 'view', {
            params: { id, configType: this.$constants.PATCH_REMOTE_DEPLOYMENT },
          }),
        }
      }
      if (model === this.$constants.PACKAGES_REMOTE_DEPLOYMENT) {
        return {
          link: this.$modules.getModuleRoute('remote-deployment', 'view', {
            params: {
              id,
              configType: this.$constants.PACKAGES_REMOTE_DEPLOYMENT,
            },
          }),
        }
      }
      if (model === this.$constants.REGISTRY_REMOTE_DEPLOYMENT) {
        return {
          link: this.$modules.getModuleRoute('remote-deployment', 'view', {
            params: {
              id,
              configType: this.$constants.REGISTRY_REMOTE_DEPLOYMENT,
            },
          }),
        }
      }
      if (model === this.$constants.USER) {
        return {
          user: {
            id,
            name: text,
          },
        }
      }
      if (model === 'request_integreation') {
        return {
          externalLink: externalLink,
        }
      }
      if (model === this.$constants.TASK) {
        return {
          task: {
            id,
            name: text,
          },
        }
      }
      if (model === this.$constants.PROJECT) {
        return {
          link: this.$modules.getModuleRoute('project', 'view', {
            params: { id },
          }),
        }
      }
      if (model === 'asset_geo_location_history') {
        return {
          geolocationAudit: {
            id,
            name: text,
            extraData: extraData,
          },
        }
      }
      if (model === 'maker_checker') {
        return {
          assetAudit: {
            id,
            name: text,
          },
        }
      }

      return {}
    },
    getLocationCoordinates(data) {
      const coordinates = [
        {
          latLng: {
            lat: data[0],
            lng: data[1],
          },
        },
      ]
      return coordinates
    },
  },
}
</script>
